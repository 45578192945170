<template>
  <v-snackbar :timeout="6000" v-model="status" color="transparent" elevation="0" right top class="pa-0 ma-0">
    <v-alert class="pa-5 ma-0" elevation="5" :type="tipoAlerta" v-html="mensagem"></v-alert>
  </v-snackbar>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";

export default {
  name: "AppNotificacao",
  mixins: [],
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({notificacao: "getNotificacao"}),
    categoria() {
      return Number(this.notificacao.categoria);
    },
    mensagem() {

      if (typeof this.notificacao.mensagem === "object" && this.notificacao.mensagem != null) {
        var msgErro = '';
        for (const [index, value] of Object.entries(this.notificacao.mensagem)) {
          msgErro += value + "<br>"
        }
        return msgErro;

      }
      return this.notificacao.mensagem;

    },
    status: {
      get() {
        return this.notificacao.status;
      },
      set() {
        this.resetNotificacao();
      },
    },
    tipoAlerta() {
      if (this.categoriaAlerta) {
        return "warning";
      } else if (this.categoriaErro) {
        return "error";
      } else {
        return "success";
      }
    },
    categoriaErro() {
      return this.categoria >= 400 && this.categoria < 600 && !this.categoriaAlerta;
    },
    categoriaAlerta() {
      return [403, 422, 429].includes(this.categoria);
    },
    categoriaSucesso() {
      return this.categoria >= 200 && this.categoria < 300;
    },
  },
  methods: {
    ...mapMutations({resetNotificacao: "RESET_NOTIFICACAO"}),
  },
  watch: {},
  created() {
  },
};
</script>

<style scoped lang="scss">
.v-snack::v-deep .v-snack__content {
  padding: 0 !important;
}

.v-snack__content {
  height: 100%;
}
</style>