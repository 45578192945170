<template>
	<v-overlay :absolute="false" :value="loading" style="z-index: 99999999;">
		<v-progress-circular :size="80" :width="8" color="orange" indeterminate />
	</v-overlay>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "AppLoading",
	mixins: [],
	components: {},
	data() {
		return {};
	},
	computed: {
        ...mapGetters({ loading: "getLoading" }),
    },
	methods: {},
	watch: {},
	created() {},
};
</script>

<style scoped>

</style>