<template>
    <div>
        <app-notificacao />
		<app-loading />
		<router-view />
    </div>
</template>

<script>
import AppLoading from "@/components/AppLoading";
import AppNotificacao from "@/components/AppNotificacao";

export default {
	name: "Boot",
	mixins: [],
	components: {
        AppLoading,
        AppNotificacao
    },
	data() {
		return {};
	},
	computed: {},
	methods: {},
	watch: {},
	created() {},
};
</script>

<style lang="scss">
.v-data-table__mobile-row__cell {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	width: 100% !important;
	max-width: 100% !important;
	text-align: left !important;
}

.v-data-table__mobile-row__header {
	width: 100% !important;
	max-width: 100% !important;
}

.v-data-table__mobile-row {
	display: block !important;
}

.v-data-table__mobile-row .v-data-table__mobile-row__header:first-child {
	padding-top: 15px;
}

.v-data-table__mobile-table-row .v-data-table__mobile-row__cell {
	margin-top: 5px;
}

.v-data-table__mobile-table-row td:last-child .v-data-table__mobile-row__cell {
	padding-bottom: 15px;
}

.theme--light.v-application{
  background-color: var(--cor-background-cinza);
}
</style>